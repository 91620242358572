<template>
  <div id="questionnaire-page">
    <h1>{{ $t("DOCTOR_QUESTIONNAIRE.TITLE") }}</h1>
    <div
      v-if="questionnaireQuestions.length > 0"
      class="questionnaire-section mt-4"
    >
      <div
        v-for="(question, idx) in questionnaireQuestions"
        :key="'question' + idx"
        class="question"
      >
        <h4>{{ question.question }}</h4>
        <template v-if="question.type === 'MCQ'">
          <b-form-group>
            <b-form-radio
              v-for="(opt, idx) in question.options"
              :key="'option' + idx"
              v-model="answers[question.id]"
              @change="checkIfFocused"
              @blur="leaveInput"
              :name="'mcq-question-' + question.id"
              :value="opt.value"
              >{{ opt.text }}</b-form-radio
            >
          </b-form-group>
        </template>
        <template v-else>
          <b-form-textarea
            id="textarea"
            v-model="answers[question.id]"
            :placeholder="$t('COMMON.WRITE_STH')"
            @focus="checkIfFocused"
            @blur="leaveInput"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </template>
      </div>
      <div class="d-flex justify-content-end">
        <b-button class="btn-success mr-2" @click="skip">{{
          $t("COMMON.SKIP")
        }}</b-button>
        <b-button
          v-if="eventDoctor && !eventDoctor.questionnaireSubmitted"
          class="btn-primary"
          @click="onSubmit"
          >{{ $t("COMMON.SUBMIT") }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  created() {
    this.getQuestionnaireQuestions({ eventId: this.$route.params.eventId });
    // this.connect();
  },
  destroyed() {
    // this.disconnect();
  },
  watch: {
    // focused() {
    //   if (this.firstTime && !this.focused) {
    //     setTimeout(() => {
    //       this.send("STOPPED");
    //       this.firstTime = false;
    //     }, 15000);
    //   } else if (this.firstTime && this.focused) {
    //     setTimeout(() => {
    //       this.send("STOPPED");
    //       this.firstTime = false;
    //     }, 120000);
    //   }
    // },
  },
  methods: {
    ...mapActions({
      getQuestionnaireQuestions: "getQuestionnaireQuestions",
      submitQuestionnaire: "submitQuestionnaire",
    }),
    ...mapMutations({
      SET_QUESTIONNAIRE_QUESTIONS: "SET_QUESTIONNAIRE_QUESTIONS",
      SET_DOCTOR_EXIST: "SET_DOCTOR_EXIST",
    }),
    resetFields() {
      this.SET_QUESTIONNAIRE_QUESTIONS([]);
      this.answers = [];
    },
    send(message) {
      // console.log("Send message:" + this.send_message);
      if (this.stompClient && this.stompClient.connected) {
        const msg = { name: message };
        this.stompClient.send(
          "/app/questionnaire_status",
          JSON.stringify(msg),
          {}
        );
        this.msgSent = true;
      }
    },
    onSubmit() {
      const questions = [];

      for (let obj in this.answers) {
        questions.push({
          questionId: obj,
          answer: this.answers[obj],
        });
      }

      const data = {
        eventId: this.$route.params.eventId,
        doctorId: this.$route.params.doctorId,
        questions,
      };

      this.submitQuestionnaire({
        vm: this,
        data,
      });
    },
    connect() {
      this.socket = new SockJS(
        "http://172.81.180.243:8080/Novonordisk2/gs-guide-websocket"
      );
      // this.socket = new SockJS("http://localhost:8080/gs-guide-websocket");
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect(
        {},
        (frame) => {
          this.connected = true;
          // console.log(frame);
          this.stompClient.subscribe("/topic/questionnaire", (tick) => {
            // console.log(tick);
            // this.received_messages.push(JSON.parse(tick.body).content);
          });
        },
        (error) => {
          // console.log(error);
          this.connected = false;
        }
      );
    },
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.connected = false;
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },
    skip() {
      this.$router.push({
        name: "Event Details",
        params: { id: this.$route.params.eventId },
      });
      this.SET_DOCTOR_EXIST(null);
    },
    checkIfFocused() {
      if (!this.firstTime) {
        this.firstTime = true;
        this.send("STARTING");
      }

      this.focused = true;
    },
    leaveInput() {
      this.focused = false;
    },
  },
  data() {
    return {
      answers: {},
      received_messages: [],
      send_message: null,
      connected: false,
      msgSent: false,
      firstTime: false,
      focused: false,
    };
  },
  computed: {
    ...mapGetters({
      questionnaireQuestions: "questionnaireQuestions",
      eventDoctor: "eventDoctor",
    }),
  },
};
</script>

<style lang="scss" scoped>
#questionnaire-page {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }

  .questionnaire-section {
    .question {
      border: 1px solid lightgray;
      padding: 10px;
      margin-bottom: 12px;
      .form-group {
        margin-bottom: 0 !important;
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #3c4eba;
        margin-bottom: 10px !important;
      }
    }

    .btn-primary,
    .btn-success {
      width: 200px;
    }
  }
}
</style>
